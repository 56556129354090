import { PbsKidsMultiPlatformSeries } from '@/types/pbskids-graph';

type EpisodeType = {
  endTime: string
  minutes: number
  nolaEpisode: string
  startTime: string
  title: string
}

type WhatsOnNowType = {
  episode?: EpisodeType
  series?: PbsKidsMultiPlatformSeries
};

const defaultTimezone = 'America/New_York';

const getEndOfCurrentHalfHourInMils = () => {
  const endOfHalfHour = new Date();
  const next30Min = Math.ceil(endOfHalfHour.getMinutes() / 30) * 30;
  endOfHalfHour.setMinutes(next30Min);
  return endOfHalfHour.getTime();
};

const getDiffBtwNowAndEndOfHalfHour = () => {
  const diff = getEndOfCurrentHalfHourInMils() - new Date().getTime();
  return diff > 0 ? diff : 0;
};

const getMilsBeforeEpisodeEnd = async (episodeEnd: string, overnight: boolean): Promise<number> => {
  if (episodeEnd.length) {
    const { DateTime } = await import('luxon');

    const now = DateTime.now();
    const currEpisodeEndDate = overnight ? now.plus({ days: 1 }) : now;
    const currEpisodeEnd = currEpisodeEndDate.set(
      {
        hour: Number(episodeEnd.slice(0, 2)),
        minute: Number(episodeEnd.slice(2, 4)),
      },
    );
    return currEpisodeEnd.diff(now).milliseconds;
  }
  return 0;
};

const getStreamTimezone = (stationTimezone: string|null): string => {
  let adjusted = stationTimezone;

  // The following timezones actually use the EST stream
  switch (stationTimezone) {
    case 'America/Chicago':
    case 'America/Puerto_Rico':
    case 'America/St_Thomas':
      adjusted = 'America/New_York';
      break;
  }

  return adjusted || defaultTimezone;
};

export type {
  EpisodeType,
  WhatsOnNowType,
};

export {
  defaultTimezone,
  getDiffBtwNowAndEndOfHalfHour,
  getMilsBeforeEpisodeEnd,
  getStreamTimezone,
};
