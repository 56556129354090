import { createContext, useContext, useEffect, useRef, useState, ReactNode } from 'react';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import {
  defaultTimezone,
  getStreamTimezone,
  EpisodeType,
  getDiffBtwNowAndEndOfHalfHour,
  getMilsBeforeEpisodeEnd,
  WhatsOnNowType,
} from '@/managers/WhatsOnNow';
import { getWhatsOnNow } from '@/managers/WhatsOnNow/api';
import { isOptionalChainingSupported } from '@/utils/device-detection';
import { PbsKidsMultiPlatformSeries } from '@/types/pbskids-graph';
import Logger from '@/utils/logger';

const WhatsOnNowContext = createContext<WhatsOnNowType>({} as WhatsOnNowType);
export default WhatsOnNowContext;

export const WhatsOnNowProvider = ({ children }: { children: ReactNode }) => {
  const [ episode, setEpisode ] = useState<EpisodeType>();
  const [ series, setSeries ] = useState<PbsKidsMultiPlatformSeries>();
  const timer = useRef<number>();
  const timezone = useRef<string>();
  const { station, stationError } = useContext(StationLocalizationContext);
  const logger = new Logger({ caller: 'contexts.WhatsOnNowContext.index' });

  const queryWhatsOnNow = async (): Promise<void> => {
    if (!isOptionalChainingSupported()) {
      logger.warn('Required JavaScript features are not supported in this environment, so we cannot load the luxon package and therefore queryWhatsOnNow() is not supported.');
      return;
    }

    const { DateTime } = await import('luxon');

    const now = DateTime.now();
    const rezoned = now.setZone(timezone.current);
    const today = rezoned.toFormat('yMMdd');
    const time = rezoned.toFormat('HHmm');

    logger.debug(`Station date and time is ${today} ${time} in adjusted timezone ${timezone.current}.`);

    // TODO: When we have a specific "Whats On Now" Graph query, replace this
    // function call with the graph query, and delete managers/WhatsOnNow/api.ts.
    // We can probably delete some tests in ./index.cy.tsx at that time too.
    const data = await getWhatsOnNow(today, time);

    data?.series && setSeries(data.series);

    if (data?.episode) {
      logger.debug('Current episode is: ', data.episode);
      setEpisode(data.episode);
      // eslint-disable-next-line no-use-before-define
      queryAfterTimeout(await getMilsBeforeEpisodeEnd(data.episode?.endTime, data.episode?.overnight));
      return;
    }

    logger.warn(`A current episode listing was not found for ${rezoned.toFormat('y-MM-dd')} at ${rezoned.toFormat('HH:mm')} in timezone ${timezone.current}`);
    setEpisode(undefined);
    setSeries(undefined);
    // eslint-disable-next-line no-use-before-define
    queryAfterTimeout();
  };

  const queryAfterTimeout = (timeout?: number) => {
    /* A timeout longer than 90 min would be unusual. It could mean a user's computer
    // time is wrong, or is out of sync with their selected station. */
    const ninetyMins = 5400000;
    let mils = timeout && timeout > 0 && timeout < ninetyMins ? timeout : getDiffBtwNowAndEndOfHalfHour();

    /* If an episode end time was not available, or was negative because of differences between
    station time and a user's computer time, and we're right the end of the half hour, mils
    could still be 0. In that case, default to a 1 minute timeout. */
    mils = mils > 0 ? mils : 60000;

    const timeoutWithBuffer = mils + 2000;
    logger.debug(`Will re-query in ${(timeoutWithBuffer) / 60000} minutes.`);

    timer.current = window.setTimeout(() => {
      queryWhatsOnNow();
    }, timeoutWithBuffer);
  };

  const cancelTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = undefined;
    }
  };

  useEffect(() => {
    if (station.timezone || stationError) {
      const zone = stationError ? defaultTimezone : station.timezone;
      timezone.current = getStreamTimezone(zone);
      cancelTimer();
      queryWhatsOnNow();
    }

    return () => {
      cancelTimer();
      timezone.current = undefined;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ station, stationError ]);

  return <>
    <WhatsOnNowContext.Provider value={{
      episode,
      series,
    }}>
      { children }
    </WhatsOnNowContext.Provider>
  </>;
};
