import getSeriesByNola from '@/queries/series/getSeriesByNola.graphql';
import getSeriesByTitle from '@/queries/series/getSeriesByTitle.graphql';
import getStationTVListings from '@/queries/station/getStationTVListings.graphql';
import { logDataIssueWarning } from '@/utils/graphql/feedback';
import { Maybe, PbsKidsMultiPlatformSeries, TvScheduleListing } from '@/types/pbskids-graph';
import { queryGraphClient } from '@/utils/graphql';

type WhatsOnNowObject = {
  episode?: {
    endTime: string
    minutes: number
    nolaEpisode: string
    overnight: boolean
    startTime: string
    title: string
  }
  series?: PbsKidsMultiPlatformSeries
}

interface AdjustedTvScheduleListing extends TvScheduleListing {
  _end_time: string
  _overnight: boolean
}

const episodeEnded = (episodeEnd: string, currTime: string, overnight: boolean): boolean => {
  const episodeEndNum = Number(episodeEnd);
  const currTimeNum = Number(currTime);

  return overnight ? false : episodeEndNum <= currTimeNum;
};

const episodeOvernight = (episodeStart: string, episodeEnd: string): boolean => {
  const episodeStartNum = Number(episodeStart);
  const episodeEndNum = Number(episodeEnd);

  return episodeStartNum > episodeEndNum;
};

const getMostRecentEpisode = (
  listings: Maybe<AdjustedTvScheduleListing>[], timeStr: string,
): Maybe<AdjustedTvScheduleListing> => {
  const curr24Time = Number(timeStr);

  const pastStart = listings.filter(
    (listing) => Number(listing?.start_time) <= curr24Time);

  return pastStart?.length ? pastStart.slice(-1)[0] : null;
};

const getEpisodeEndStr = (startTime: string, minutes: number): string => {
  const episodeStartHrs = Number(startTime.slice(0, 2));
  const episodeStartMins = Number(startTime.slice(2, 4));

  const episodeDurationHours = Math.floor(minutes / 60);
  const episodeDurationMinutes = minutes % 60;

  let episodeEndHrs = episodeStartHrs + episodeDurationHours;
  let episodeEndMins = episodeStartMins + episodeDurationMinutes;

  if (episodeEndMins >= 60) {
    episodeEndHrs += Math.floor(episodeEndMins / 60);
    episodeEndMins = episodeEndMins % 60;
  }

  if (episodeEndHrs > 24 || (episodeEndHrs === 24 && episodeEndMins > 0)) {
    episodeEndHrs = episodeEndHrs - 24;
  }

  return `${episodeEndHrs.toString().padStart(2, '0')}${episodeEndMins.toString().padStart(2, '0')}`;
};

const findCurrentListing = (
  listings: Maybe<AdjustedTvScheduleListing>[], timeStr: string,
): Maybe<AdjustedTvScheduleListing> => {
  const mostRecent = getMostRecentEpisode(listings, timeStr);

  // Only return the most recent episode if it hasn't already ended.
  if (mostRecent?.start_time && mostRecent.minutes) {
    const episodeEnd = getEpisodeEndStr(mostRecent.start_time, mostRecent.minutes);
    const overnight = episodeOvernight(mostRecent.start_time, episodeEnd);
    const ended = episodeEnded(episodeEnd, timeStr, overnight);

    if (!ended) {
      return {
        ...mostRecent,
        _end_time: episodeEnd,
        _overnight: overnight,
      };
    }
  }
  return null;
};

const queryListings = async (queryDate: string): Promise<Maybe<AdjustedTvScheduleListing[]>> => {
  const variables = {
    station_callsign: 'PBSKIDS247',
    date: queryDate,
  };
  const data = await queryGraphClient(
    getStationTVListings,
    variables,
  )
    .catch((err) => {
      throw new Error(err);
    });

  const { listings } = data.tvSchedulesFeeds[0];

  if (!(listings?.length > 0)) {
    logDataIssueWarning('No listings found in response!', getStationTVListings, variables, data);
    return [];
  }

  const hasEpWithMissingTitle = listings.find((listing: TvScheduleListing) => !listing.episode_title);
  if (hasEpWithMissingTitle) {
    logDataIssueWarning('Missing episode title in listing.', getStationTVListings, variables, data);
  }

  return listings;
};

const querySeries = async (
  nolaRoot?: string|null,
  seriesTitle?: string|null,
): Promise<Maybe<PbsKidsMultiPlatformSeries>> => {
  // Query for a series, given a nola root and a series title,
  // neither of which are guaranteed to be present or accurate!
  const seriesByNola = nolaRoot &&
    await queryGraphClient(getSeriesByNola, {
      searchParameters: {
        cmsElementType: 'Entry',
        returnTypes: [ 'PbsKidsMultiPlatformSeries', 'PbsKidsPodcast', 'PbsKidsShortsSeries', 'PbsKidsSpecial' ],
        strings: [
          { fieldHandle: 'nolaRoot', value: nolaRoot },
        ],
      },
    })
      .catch((err) => {
        throw new Error(err);
      });

  if (seriesByNola?.cmsElementSearch?.data?.length) {
    return seriesByNola.cmsElementSearch.data[0];
  }

  const seriesByTitle = seriesTitle &&
    await queryGraphClient(getSeriesByTitle, {
      searchParameters: {
        cmsElementType: 'Entry',
        returnTypes: [ 'PbsKidsMultiPlatformSeries', 'PbsKidsPodcast', 'PbsKidsShortsSeries', 'PbsKidsSpecial' ],
        strings: [
          { fieldHandle: 'title', value: seriesTitle },
        ],
      },
    }).catch((err) => {
      throw new Error(err);
    });

  if (seriesByTitle?.cmsElementSearch?.data?.length) {
    return seriesByTitle.cmsElementSearch.data[0];
  }

  return null;
};

const getWhatsOnNow = async (dateStr: string, timeStr: string): Promise<null|WhatsOnNowObject> => {
  // dateStr should be in the format '20231201'
  // timeStr shbould be in a 24 hour time string like '0900', or '1330'.
  let result: null | WhatsOnNowObject = null;

  const listings = await queryListings(dateStr);
  const listing = listings?.length ? findCurrentListing(listings, timeStr) : null;

  if (listing) {
    result = {
      episode: {
        title: listing.episode_title ?? '',
        minutes: listing.minutes ?? 0,
        endTime: listing._end_time ?? 0,
        nolaEpisode: listing.nola_episode ?? '',
        overnight: listing._overnight ?? false,
        startTime: listing.start_time ?? '',
      },
    };
  }

  if (listing?.nola_root || listing?.title) {
    const seriesData = await querySeries(listing.nola_root, listing.title);

    if (seriesData) {
      result = result || {};
      result['series'] = seriesData;
    }
  }

  return result;
};

export { getWhatsOnNow };
