function isIpadOS() {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);
}

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return isIpadOS();
  }
}

// Source: https://stackoverflow.com/a/69935847
const isOptionalChainingSupported = () => {
  try {
    const foo = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore-next-line
    foo?.bar;
  } catch {
    return false;
  }

  return true;
};

export {
  isIOS,
  isIpadOS,
  isOptionalChainingSupported,
};
