import { convertTimeToIsoDate, serializeDuration } from '@/managers/VideoPlayer/duration';
import { WhatsOnNowType } from '@/managers/WhatsOnNow';
import { PbsKidsVideo } from '@/types/pbskids-graph';

/* Private Methods
************************/
const videoImageURLs = (videoData: PbsKidsVideo) => {
  const imageURLs: (string | undefined)[] = [];
  if (videoData?.mediaManagerAsset?.images) {
    const images = videoData.mediaManagerAsset.images;

    images.forEach((image) => {
      imageURLs.push(image?.image);
    });
  }
  return imageURLs;
};

const videoURL = (videoData: PbsKidsVideo) => {
  const videos = videoData.mediaManagerAsset?.videos;
  if (videos && videos[0]) {
    return videos[0].url;
  }
};

const richSearchData = (videoData: PbsKidsVideo) => {
  return {
    name: videoData.mediaManagerAsset?.title || videoData.title,
    description: videoData.mediaManagerAsset?.description_short || videoData.mediaManagerAsset?.description_long,
    thumbnailURL: videoImageURLs(videoData),
    uploadDate: convertTimeToIsoDate(videoData?.dateCreated),
    duration: serializeDuration(videoData.mediaManagerAsset?.duration),
    contentUrl: videoURL(videoData),
    expiryDate: convertTimeToIsoDate(videoData?.expiryDate),
  };
};

/* Public Methods
************************/

const getShowTitle = (video?: PbsKidsVideo): string => video?.properties?.[0]?.title ?? '';

const getVideoTitle = (video?: PbsKidsVideo): string => video?.mediaManagerAsset?.title ?? video?.title ?? '';

const getDisplayTitle = (video?: PbsKidsVideo): string => {
  const showTitle = getShowTitle(video);
  const videoTitle = getVideoTitle(video);
  return showTitle ? `${showTitle}: ${videoTitle}` : videoTitle;
};

const getDebugTitle = (video?: PbsKidsVideo): string => {
  const padOrdinal = (num: number) => num.toString().padStart(2, '0');

  if (video?.mediaManagerAsset) {
    const showTitle = getShowTitle(video);
    const episodeNumber = video.mediaManagerAsset.episode_number || '';
    const seasonNumber = video.mediaManagerAsset.season_number || '';
    const ordinals = episodeNumber && seasonNumber ? `(S${ padOrdinal(seasonNumber) }E${ padOrdinal(episodeNumber) })` : '';
    return showTitle ? `${showTitle} ${ordinals || ':'} ${video.title}` : video.title || '';
  }
  return '';
};

const getIsNew = (video?: PbsKidsVideo): boolean => {
  if (!video?.mediaManagerAsset?.premiered_on) return false;

  // Craft premierDate is in seconds instead of milliseconds,
  // therefore convert all Date times to seconds.
  const premierDateInSeconds = parseInt(video.mediaManagerAsset.premiered_on);
  const oneWeekInSeconds = 7 * 24 * 3600;
  const nowInSeconds = Date.now() / 1000;

  // Is "new" if the premiere date is less than 1 week ago.
  return (nowInSeconds - oneWeekInSeconds < premierDateInSeconds);
};

const getPoster = (video?: PbsKidsVideo): string => {
  const posterImageObj = video?.mediaManagerAsset?.images?.filter((item) => item?.profile === 'asset-kids-mezzanine1-16x9') || null;
  return posterImageObj?.[0]?.image ?? '';
};

const getLivestreamEpisodeAsVideoObj = (whatsOnNowData: WhatsOnNowType): PbsKidsVideo => {
  return {
    id: '',
    guid: '',
    title: whatsOnNowData?.episode?.title ?? '',
    properties: whatsOnNowData?.series ? [ whatsOnNowData.series ] : [],
    videoType: 'fullEpisode',
    mediaManagerAsset: {
      legacy_tp_media_id: whatsOnNowData?.episode?.nolaEpisode ?? '',
    },
  };
};

const getPreRollAsVideoObj = (): PbsKidsVideo => {
  return {
    id: '',
    guid: '',
    title: 'KIDS Livestream Preroll',
    videoType: 'promo',
  };
};

const getVideoDataObj = (
  video?: PbsKidsVideo,
  isPreRoll?: boolean,
  isLive?: boolean,
  whatsOnNow?: WhatsOnNowType,
): PbsKidsVideo | null => {
  let videoObj = null;

  if (video) {
    videoObj = isPreRoll ? getPreRollAsVideoObj() : video;
  } else if (isLive && whatsOnNow) {
    videoObj = getLivestreamEpisodeAsVideoObj(whatsOnNow);
  }

  return videoObj;
};

const getRichSearchJSON = (videoData: PbsKidsVideo) => {
  const JSONHeaders = '{ "@context": "https://schema.org", "@type": "VideoObject"}';
  const headerObj = JSON.parse(JSONHeaders);
  const mergedObj = {
    ...headerObj,
    ...richSearchData(videoData),
  };

  return JSON.stringify(mergedObj);
};

export {
  getDebugTitle,
  getDisplayTitle,
  getIsNew,
  getLivestreamEpisodeAsVideoObj,
  getPoster,
  getPreRollAsVideoObj,
  getShowTitle,
  getVideoDataObj,
  getVideoTitle,
  getRichSearchJSON,
};
